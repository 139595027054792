<template>
  <div class="main-container">
    <div class="header">
      <v-container>
        <div class="header-top">
          <router-link to="/">
            <a class="logo">
              <img
                src="~@/assets/logo.png"
                alt="Westside Nannies"
                class="m-hide"
              />
              <img
                src="~@/assets/logo-mobile.png"
                alt="Westside Nannies"
                class="d-hide"
              />
            </a>
          </router-link>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  //props: ['referencesURLId'],
  data() {
    return {
      referencesURLId: 'receGfhBbwtvNst3R'
      //
    };
  },
};
</script>
