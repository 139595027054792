<template>
  <div class="main-container petite-crew">
    <div class="header bg-pc-tan">
      <v-container>
        <div class="header-top bg-pc-tan">
          <router-link to="/">
            <a class="logo">
              <img
                src="~@/assets/pctan.png"
                alt="Petite Crew"
                class="m-hide"
                style="max-height: 81px;"
              />
              <img
                src="~@/assets/pctan.png"
                alt="Petite Crew"
                class="d-hide"
                style="max-height: 81px;"
              />
            </a>
          </router-link>
        </div>
      </v-container>
      <div class="main-banner">
        <img src="~@/assets/pc-banner.png" alt="Banner" />
        <v-container>
          <div class="banner-text">
            <div class="h-title">Reference Report</div>
            <div class="name">{{results.Name}}</div>
          </div>
        </v-container>
      </div>
    </div>
    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">References</h2>
            <h3 class="table-subtitle text-center">
              The following is a list of references who participated in this
              report:
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table3 bg-pc-tan">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="370">
                    <span class="text-petite-crew">Reference</span>
                  </th>
                  <th class="text-left" width="220">
                    <span class="text-petite-crew">Company/Title</span>
                  </th>
                  <th class="text-left" width="220">
                    <span class="text-petite-crew">Contact Info</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in results.References" :key="'A' + i">
                  <td>
                    <b>{{ item.Reference }}</b>
                  </td>
                  <td>
                    {{ item.Company }}
                  </td>
                  <td>
                    {{ item.Contact }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="table-btm-info">
            Petite Crew cannot confirm the accuracy of any of the
            information provided by the caregiver's references.
          </div>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">
              Comments On How Working <br />Relationship Ended
            </h2>
            <h3 class="table-subtitle text-center">
              The following comments were provided by references when asked
              <br />"why did the position end or why is it ending?"
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.RelationshipComments" :key="'B' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td>{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Number/Ages of Children Under Care</h2>
          </div>
          <v-simple-table
            class="custom-responsive-table bg-pc-tan"
            style="max-width: 632px; margin: 0 auto"
          >
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.ChildrenAges" :key="'C' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">
              Comments on Candidate's <br />Role &amp; Responsibilities
            </h2>
            <h3 class="table-subtitle text-center">
              The following comments were provided by references when asked to
              describe <br />
              the candidate's responsibilities in the role.
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.RolesResponsibilityComments" :key="'D' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td>{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Assessment of Skills</h2>
          </div>
          <v-carousel height="auto" class="custom-skills-carousel d-hide">
            <v-carousel-item
              v-for="(user, userIndex) in skillsUsersList"
              :key="'E' + userIndex"
            >
              <v-simple-table class="assessment-skills-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>{{ user.Reference }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(skill, i) in results.AssessmentSkills">
                      <tr v-if="!skill.length == 0" :key="'F' + i">
                        <template v-for="(value, i) in skill.slice(0, 1)">
                          <td :key="'section' + i">
                            {{value.Section}}
                          </td>
                          <template v-for="(value, countIndex) in skill">
                            <td v-if="userIndex == countIndex" :key="value.id">
                              {{ value.Rating }}<span>/5</span>
                            </td>
                          </template>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-carousel-item>
          </v-carousel>
          <v-simple-table class="assessment-skills-table m-hide">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th
                    v-for="(item, usersIndex) in skillsUsersList"
                    :key="'H' + usersIndex"
                  >
                    {{ item.Reference }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in results.AssessmentSkills">
                  <tr v-if="!item.length == 0" :key="i">
                    <template v-for="(value, i) in item.slice(0, 1)">
                      <td :key="'section' + i">
                        {{value.Section}}
                      </td>
                      <td v-for="(value, i) in item" :key="'F' + i">
                        {{value.Rating}}<span>/5</span>
                      </td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Candidate Traits</h2>
            <h3 class="table-subtitle text-center">
              The following section is a summary of the traits references used
              to describe the candidate:
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.Traits" :key="'K' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">
                    <span class="fs-16">{{ item.Notes }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Candidate Strengths</h2>
            <h3 class="table-subtitle text-center">
              The following section is a summary of the strengths references
              attributed to the candidate:
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table bg-pc-tan">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.Strengths" :key="'L' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">
                    <span class="fs-16">{{ item.Notes }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Areas for Improvement</h2>
            <h3 class="table-subtitle text-center">
              The following section is a summary of the areas that references
              felt the candidate could improve:
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.Improvement" :key="'M' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td>{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan" v-if="hasMemories">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">
              Special Memories of Candidate
            </h2>
          </div>
          <v-carousel class="special-memories-carousel" height="auto" light>
            <v-carousel-item
              v-for="(item, i) in results.Memories"
              :key="'N' + i"
            >
              <div class="carousel-card">
                <v-avatar circle size="320">
                  <img src="~@/assets/angelica.jpg" alt="" />
                </v-avatar>
                <div class="carousel-card-content">
                  <p class="card-subtitle">
                    {{ item.Notes }}
                  </p>
                  <h4 class="card-title">
                    {{ item.Reference }}
                  </h4>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">
              Confidence in Ability to <br />Handle an Emergency
            </h2>
            <h3 class="table-subtitle text-center">
              The following comments were provided by references when asked if
              they felt confident the <br />candidate could handle an emergency
              situation:
            </h3>
          </div>
          <v-simple-table
            class="custom-responsive-table2"
            style="max-width: 632px; margin: 0 auto"
          >
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.Emergency" :key="'O' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">
                    <img
                      v-if="item.Notes == 'true' || item.Notes == true"
                      src="~@/assets/thumb3.png"
                      alt="Thumbs Up"
                    />
                    <img
                      v-else
                      src="~@/assets/thumb4.png"
                      alt="Thumbs Down"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Overall Experience Rating</h2>
            <v-row no-gutters justify="center">
              <v-col cols="12" sm="8">
                <h3 class="table-subtitle text-center">
                  The following ratings were provided by references with regards
                  to their overall experience working with the candidate:
                </h3>
              </v-col>
            </v-row>
          </div>
          <v-simple-table
            class="custom-responsive-table bg-pc-tan"
            style="max-width: 632px; margin: 0 auto"
          >
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.OverallRating" :key="'P' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">
                    <v-rating :value="parseInt(item.Notes)" length="5" size="20">
                      <template v-slot:item="props">
                        <v-icon
                          size="28"
                          :color="props.isFilled ? '#ed7448' : '#DADADA'"
                        >
                          mdi-heart
                        </v-icon>
                      </template>
                    </v-rating>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner pt-0">
          <div class="section-header">
            <h2 class="table-title text-center">Overall Recommendation</h2>
            <v-row no-gutters justify="center">
              <v-col cols="12" sm="8">
                <h3 class="table-subtitle text-center">
                  The following comments were provided by references when asked
                  if they felt comfortable recommending the candidate:
                </h3>
              </v-col>
            </v-row>
          </div>
          <v-simple-table
            class="custom-responsive-table2  bg-pc-tan"
            style="max-width: 632px; margin: 0 auto"
          >
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.OverallRecommendation" :key="'Q' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-center">
                    <img
                      v-if="item.Notes == 'true' || item.Notes == true"
                      src="~@/assets/thumb1.png"
                      alt="Thumbs Up"
                    />
                    <img
                      v-else
                      src="~@/assets/thumb2.png"
                      alt="Thumbs Down"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section class="section-wrap bg-petite-crew">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Notes for Consideration</h2>
            <v-row no-gutters justify="center">
              <v-col cols="12" sm="9">
                <h3 class="table-subtitle text-center">
                  The following comments were provided by references when asked
                  if they had any comments for those considering hiring the
                  candidate:
                </h3>
              </v-col>
            </v-row>
          </div>
          <v-simple-table class="custom-responsive-table">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.NotesConsideration" :key="'R' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-left">{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <section v-if="results.AdditionalComments.length > 0" class="section-wrap bg-pc-tan">
      <v-container>
        <div class="section-wrap-inner">
          <div class="section-header">
            <h2 class="table-title text-center">Additional Comments</h2>
            <h3 class="table-subtitle text-center">
              The following comments were provided by references when asked if
              they had any final comments to add:
            </h3>
          </div>
          <v-simple-table class="custom-responsive-table bg-pc-tan">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in results.AdditionalComments" :key="'S' + i">
                  <th width="370">{{ item.Reference }}</th>
                  <td class="text-left">{{ item.Notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>
    </section>

    <v-footer light padless>
      <div class="footer-address text-center">
        <h4>Contact Us.</h4>
        <h5>310.359.5300</h5>
        <p>
          <a href="mailto: info@petitecrew.com"
            >info@petitecrew.com</a
          >
        </p>
      </div>
      <div class="copyrights text-center">
        <p>
          &copy; {{ new Date().getFullYear() }} by Petite Crew.
          <span>All rights reserved.</span>
        </p>
        <ul>
          <li><a href="#">Terms of Use</a></li>
          <li><a href="#">Privacy Policy</a></li>
        </ul>
      </div>
    </v-footer>
    <div v-if="loading" class="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-alert type="error" v-if="isError" class="error-alert">
      We have not found Data.
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "References",
  props: {
    msg: String,
  },
  data() {
    return {
      loading: true,
      isError: false,
      hasMemories: true,
      results: {},
      skillsUsersList: [],
      referencesId: this.$route.params.id,
    };
  },
  created: function () {
    axios.get("https://nck-district12-6iel3733vq-uc.a.run.app/wsn/tcRef?id="+this.referencesId)
    .then((response) => {
        this.loading = false,
        this.results = response.data.result;

        if(this.results.Memories.length === 0){
          this.hasMemories = false;
        }

        if(this.results){
          this.skillsUsersList = response.data.result.AssessmentSkills.cellPhone;
        }
    })
    .catch((error) => {
        console.error(error);
        this.isError = true;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.petite-crew {
  .v-application {
    font-family: "Quicksand", sans-serif !important;
  }
  .theme--light.v-application {
    color: #000 !important;
  }
  .main-container {
    padding-top: 0px;
  }
  .container {
    max-width: 1304px !important;
    padding-top: 0px !important;
    font-family: "Quicksand", sans-serif;
  }
  .error-alert{
    position: fixed !important;
    top: 20px;
    right: 20px;
  }
  .loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .v-progress-circular{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  img {
    max-width: 100%;
  }
  @for $j from 1 to 50 {
    .fs-#{$j} {
      font-size: $j + px;
    }
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .header {
    .container {
      padding-top: 0;
      padding-bottom: 0;
    }
    .header-top {
      padding: 18px 44px 20px;
      background: #fff;
      .logo {
        img {
          display: block;
        }
      }
    }
    .main-banner {
      position: relative;
      .container {
        position: relative;
      }
      img {
        display: block;
        object-fit: cover;
        width: 100%;
        object-position: center;
        height: 600px;
      }
      .banner-text {
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        padding-bottom: 133px;
        padding-left: 102px;
        .h-title {
          font-size: 45px;
          font-weight: 700;
          line-height: 1;
        }
        .name {
          font-size: 45px;
          font-weight: normal;
          line-height: 1;
          padding-top: 7px;
        }
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .ref-page {
    margin-top: 100px;

    .p-header {
      .p-title {
        font-size: 40px;
        color: #43b8b0;
      }
      .p-subtitle {
        font-size: 40px;
        color: #43b8b0;
        margin-top: -15px;
      }
      img {
        width: 130px;
      }
    }
  }

  .v-data-table > .v-data-table__wrapper > table {
    border: 1px solid #9c9c9c;
    td,
    th {
      border-right: 1px solid #9c9c9c;
      border-color: #9c9c9c !important;
      padding: 15px 6px !important;
      height: auto !important;
      color: #000 !important;
      font-weight: 500;
      font-size: 14px !important;
      letter-spacing: 0.04em;
      &:last-child {
        border-right: none;
      }
    }
    th {
      font-weight: 700;
    }
  }
  .section-wrap {
    padding: 0;
    &-inner {
      padding: 105px 100px 104px;
    }
    .table-title {
      font-size: 46px;
      line-height: 56px;
      font-weight: 400;
      margin: 0 0 44px 0;
      letter-spacing: 0.04em;
    }
    .table-subtitle {
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      margin: 0 0 33px 0;
      letter-spacing: 0.04em;
    }
    .table-btm-info {
      text-align: center;
      font-style: italic;
      font-size: 19px;
      line-height: 30px;
      padding-top: 50px;
    }
    &.bg-cyan {
      .table-title {
        color: #fff;
        font-weight: 300;
      }
      .table-subtitle {
        color: #fff;
        font-weight: 300;
      }
      .v-data-table {
        background: transparent !important;
        .v-data-table__wrapper {
          table {
            border: 1px solid rgba(255, 255, 255, 0.4);
            td,
            th {
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              border-color: rgba(255, 255, 255, 0.4) !important;
              padding: 15px 5px !important;
              height: auto !important;
              color: #fff !important;
              font-weight: 300;
              &:last-child {
                border-right: none;
              }
            }
            th {
              font-weight: 600;
            }

            tbody
              > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
    &.bg-petite-crew {
      .table-title {
        color: #fff;
        font-weight: 300;
      }
      .table-subtitle {
        color: #fff;
        font-weight: 300;
      }
      .v-data-table {
        background: transparent !important;
        .v-data-table__wrapper {
          table {
            border: 1px solid rgba(255, 255, 255, 0.4);
            td,
            th {
              border-right: 1px solid rgba(255, 255, 255, 0.4);
              border-color: rgba(255, 255, 255, 0.4) !important;
              padding: 15px 5px !important;
              height: auto !important;
              color: #fff !important;
              font-weight: 300;
              &:last-child {
                border-right: none;
              }
            }
            th {
              font-weight: 600;
            }

            tbody
              > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
  .custom-skills-carousel {
    .theme--dark.v-data-table {
      background: transparent !important;
    }
    .v-window__prev,
    .v-window__next {
      display: none !important;
    }
    .v-carousel__controls {
      background: transparent;
      position: relative;
      height: auto;
      padding: 42px 0 0;
      .v-btn.v-btn--icon {
        color: #05160c;
        opacity: 1;
        height: 7px;
        width: 7px;
        margin: 0 3px;
        .v-icon {
          height: 7px;
          width: 7px;
          font-size: 8px !important;
          opacity: 1;
        }
        &:before {
          display: none;
        }
        &.v-btn--active {
          color: #3fb7ad;
        }
      }
    }
  }
  .special-memories-carousel {
    overflow: visible !important;
    padding-top: 45px;
    .v-carousel__controls {
      background: transparent;
      position: relative;
      height: auto;
      padding: 35px 0 0;
      .v-btn.v-btn--icon {
        color: #d2d2d2;
        opacity: 1;
        height: 8px;
        width: 8px;
        margin: 0 4px;
        .v-icon {
          height: 8px;
          width: 8px;
          font-size: 8px !important;
          opacity: 1;
        }
        &:before {
          display: none;
        }
        &.v-btn--active {
          color: #7ccdc6;
        }
      }
    }
    .v-window__prev {
      left: -76px !important;
    }
    .v-window__next {
      right: -76px !important;
    }
    .v-window__prev,
    .v-window__next {
      background: transparent;
      .v-btn--icon.v-size--default {
        .v-icon {
          font-size: 60px !important;
          color: #000;
        }
      }
    }
  }
  .carousel-card {
    display: flex;
    align-items: center;
    &-content {
      padding-left: 60px;
      .card-subtitle {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #000;
        letter-spacing: 0.04em;
        margin-bottom: 25px;
      }
      .card-title {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.04em;
      }
    }
  }
  .assessment-skills-table {
    .v-data-table__wrapper {
      table {
        border: none;
        thead {
          th {
            border: none !important;
            border-bottom: 1px solid #a8e1db !important;
            text-align: center !important;
            width: 115px;
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid #e4baab !important;
              border-left: none !important;
              border-top: none !important;
              text-align: center;
              font-size: 35px !important;
              font-weight: 400 !important;
              &:first-child {
                border-left: 1px solid #e4baab !important;
                font-size: 17px !important;
                width: 206px;
                padding-left: 22px !important;
                padding-right: 22px !important;
              }
            }
            &:nth-child(even) {
              td {
                background-color: #fff;
                color: #000 !important;
                span {
                  color: #dadada !important;
                }
              }
            }
            &:nth-child(odd) {
              td {
                background-color: #ed7448;
                color: #fff !important;
                span {
                  color: #e0b4a4 !important;
                }
              }
            }
          }
        }
      }
      &.grid-pc-tan table {
        border: none;
        thead {
          th {
            border: none !important;
            border-bottom: 1px solid #a8e1db !important;
            text-align: center !important;
            width: 115px;
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid #a8e1db !important;
              border-left: none !important;
              border-top: none !important;
              text-align: center;
              font-size: 35px !important;
              font-weight: 400 !important;
              &:first-child {
                border-left: 1px solid #a8e1db !important;
                font-size: 17px !important;
                width: 206px;
                padding-left: 22px !important;
                padding-right: 22px !important;
              }
            }
            &:nth-child(even) {
              td {
                background-color: #fff;
                color: #000 !important;
                span {
                  color: #dadada !important;
                }
              }
            }
            &:nth-child(odd) {
              td {
                background-color: #ed7448;
                color: #fff !important;
                span {
                  color: #91cec7 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .text-cyan {
    color: #49b7ac !important;
  }
  .text-petite-crew {
    color: #ed7448 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-pc-tan {
    background-color: #eee7d1 !important;
  }
  .bg-cyan {
    background-color: #49b7ac !important;
  }
  .bg-petite-crew {
    background-color: #ed7448 !important;
  }
  .bg-wilshire {
    background-color: #49b7ac !important;
  }
  .v-footer {
    display: block !important;
    background: #e2e2e2 !important;
    .footer-address {
      padding: 110px 0 80px 0;
      font-size: 21px;
      color: #000;
      letter-spacing: 0.04em;
      h4 {
        font-size: 32px;
        font-weight: normal;
        color: #000;
        margin-bottom: 16px;
      }
      h5 {
        font-size: 28px;
        font-weight: normal;
        color: #000;
        margin-bottom: 10px;
      }
      a {
        color: #000;
        text-decoration: none;
        position: relative;
        border-bottom: 1px solid #555;
        &:hover {
          border-bottom: none;
        }
      }
    }
    .copyrights {
      background: #fff;
      display: flex;
      justify-content: center;
      font-size: 19px;
      padding: 26px 0 26px 0;
      p {
        margin: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        li {
          padding: 0 0 0 40px;
          a {
            color: #000;
            text-decoration: none;
            position: relative;
            &:after {
              content: "";
              width: 100%;
              height: 1px;
              background-color: #000;
              position: absolute;
              left: 0;
              bottom: 2px;
            }
            &:hover {
              &:after {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1281px) {
    .header {
      .main-banner {
        img {
          height: 700px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .d-hide {
      display: none !important;
    }
  }
  @media screen and (max-width: 767px) {
    .m-hide {
      display: none !important;
    }
    .header {
      .header-top {
        padding: 10px 9px 12px;
        .logo {
          img {
            max-width: 204px;
          }
        }
      }
      .main-banner {
        position: relative;
        &:before {
          content: "";
          background-image: url("~@/assets/banner-overlay.png");
          background-size: cover;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .banner-text {
          padding: 0;
          text-align: center;
          bottom: 9rem;
          width: 100%;
          .h-title {
            font-size: 38px;
          }
          .name {
            font-size: 38px;
            line-height: 42px;
            font-weight: 300;
            letter-spacing: 0.04em;
          }
        }
      }
    }
    .v-data-table > .v-data-table__wrapper > table {
      td,
      th {
        font-size: 13px !important;
      }
    }
    .section-wrap {
      &-inner {
        padding: 52px 7px 48px;
        .section-header {
          .table-title {
            font-size: 38px;
            line-height: 48px;
            margin-bottom: 24px;
            br {
              display: none;
            }
          }
          .table-subtitle {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 39px;
            br {
              display: none;
            }
          }
        }
      }
      .table-btm-info {
        font-size: 18px;
        padding: 10px 30px 0;
      }
      &.bg-cyan {
        .v-data-table.custom-responsive-table2 {
          .v-data-table__wrapper {
            table {
              th {
                width: 215px;
              }
              th,
              td {
                padding: 25px 5px !important;
                img {
                  display: block;
                  margin: 0 auto;
                  max-width: 54px;
                }
              }
            }
          }
        }
      }
      &.bg-petite-crew {
        .v-data-table.custom-responsive-table2 {
          .v-data-table__wrapper {
            table {
              th {
                width: 215px;
              }
              th,
              td {
                padding: 25px 5px !important;
                img {
                  display: block;
                  margin: 0 auto;
                  max-width: 54px;
                }
              }
            }
          }
        }
      }
    }
    .custom-responsive-table {
      .v-data-table__wrapper {
        table {
          tr {
            display: block;
            th,
            td {
              display: block;
              text-align: center !important;
              width: 100% !important;
              border-right: none !important;
              font-size: 13px !important;
              .v-rating {
                .v-icon {
                  padding: 0 0.5rem;
                }
              }
            }
            &:last-child {
              td {
                border-top: 1px solid #9c9c9c;
              }
            }
          }
        }
      }
    }
    .v-data-table.custom-responsive-table2 {
      .v-data-table__wrapper {
        table {
          th {
            width: 216px;
          }
          th,
          td {
            padding: 25px 5px !important;
            img {
              display: block;
              margin: 0 auto;
              max-width: 54px;
            }
          }
        }
      }
    }
    .v-data-table.custom-responsive-table3 {
      .v-data-table__wrapper {
        /* Force table to not be like tables anymore */
        table {
          border: none;
        }
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
        tr {
          border: 1px solid #9c9c9c;
          border-bottom: none;
          margin-bottom: 30px;
        }
        td {
          /* Behave like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50% !important;
        }
        td:before {
          position: absolute;
          top: 0;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
          bottom: 0;
          display: flex;
          align-items: center;
          border-right: 1px solid #9c9c9c;
          color: #49b7ac !important;
          font-weight: 700;
        }
        /*
        Label the data
        */
        td:nth-of-type(1):before {
          content: "Reference";
        }
        td:nth-of-type(2):before {
          content: "Company/Title";
        }
        td:nth-of-type(3):before {
          content: "Contact Info";
        }
        td:nth-of-type(4):before {
          content: "Notes";
        }
      }
    }
    .special-memories-carousel {
      padding-top: 34px;
      .v-carousel__controls {
        .v-btn.v-btn--icon {
          color: #05160c;
          margin: 0 3px;
          &.v-btn--active {
            color: #3fb7ad;
          }
        }
      }
      .v-window__prev,
      .v-window__next {
        display: none !important;
      }
    }
    .carousel-card {
      flex-direction: column;
      &-content {
        padding-top: 50px;
        padding-left: 0;
        .card-subtitle {
          font-size: 17px;
          line-height: 25px;
          margin-bottom: 15px;
          text-align: center;
        }
        .card-title {
          font-size: 17px;
          font-weight: 700;
          text-align: center;
        }
      }
    }
    .v-footer {
      .footer-address {
        font-size: 18px;
        padding: 50px 0 44px 0;
        h4 {
          font-size: 28px;
          margin-bottom: 13px;
        }
        h5 {
          font-size: 18px;
          margin-bottom: 27px;
        }
        p {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .copyrights {
        flex-direction: column;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.04em;
        p {
          span {
            display: block;
          }
        }
        ul {
          flex-direction: column;
          li {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>