import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ReferencesComponent from "../components/Viewer.vue"
import pcReferencesComponent from "../components/pcViewer.vue"
import waReferencesComponent from "../components/waViewer.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wsn/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wsn/references/:id?",
    name: "references",
    component: ReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/pc/:id?",
    name: "references",
    component: pcReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/pc/references/:id?",
    name: "references",
    component: pcReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wa/:id?",
    name: "references",
    component: waReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  },
  {
    path: "/wa/references/:id?",
    name: "references",
    component: waReferencesComponent,
    props: true,
    // props(route) {
    //   return {  id: route.query.id }
    // }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
